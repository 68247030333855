<script setup>
import { defineProps } from 'vue';

const props = defineProps({
    image: String,
    name: String,
    email: String,
    size: {
      type: String,
      required: true,
      validator: (value) => ['small', 'medium'].includes(value)
    }
})

const getInitials = (name, email) => {
  if (!name) return;
  const firstLetter = name.substring(0, 1) || email.substring(0, 1) || null
  return firstLetter;
}

const style = {
  width: props.size === 'medium' ? '30px' : '20px',
  height: props.size === 'medium' ? '30px' : '20px',
  fontSize: props.size === 'medium' ? '15px' : '10px'
}

</script>

<template>
    <div class="user-container" :style="style">
        <img class="user-bg" v-if="props.image" :src="props.image.startsWith('http') ? props.image : `data:image/png;base64,${props.image}`" />
        <span class="user-bg" v-else>{{ getInitials(props.name, props.email) }}</span>
    </div>
</template>

<style scoped>
.user-container {
    border-radius: 50%;
    border: 1px solid #dbdbdb;
    font-size: 15px;
    color: #fff;
    background-color: #00000098;

    display: flex;
    align-items: center;
    justify-content: center;
}

img {
  border-radius: 50%;
  background-color: transparent;
}

span {
  font-weight: 300;
}

</style>